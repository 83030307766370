const patchManagerWindowsState = {
    filters: {},
    change: false,
    filtersType: {
        windows_type: {
            label: 'Windows Type',
            default: true,
            show: true,
            options: [
                { value: [0, 1], label: 'Workstations' },
                { value: [2, 3, 4, 5], label: 'Servers' }
            ]
        },
        uninstallable: {
            label: 'Uninstallable',
            default: true,
            show: true,
            options: [
                { value: 1, label: 'yes' },
                { value: 0, label: 'no' }
            ]
        },
        asset_status: {
            label: 'Asset Status',
            default: true,
            show: true,
            options: [
                { value: 'Online', label: 'Online' },
                { value: 'Offline', label: 'Offline' }
            ]
        },
        reboot_required: {
            label: 'Reboot Required',
            default: true,
            show: true,
            options: [
                { value: 1, label: 'yes' },
                { value: 0, label: 'no' }
            ]
        },
        groups: {
            label: 'Groups',
            default: false,
            show: false,
            optionsName: 'groups'
        },
        serial_number: {
            label: 'Serial Number',
            default: false,
            show: false,
            optionsName: 'serial_number'
        },
        operating_system: {
            label: 'Operating System',
            default: false,
            show: false,
            optionsName: 'operating_system'
        },
        update_category: {
            label: 'Update Category',
            default: false,
            show: false,
            optionsName: 'update_category'
        }
    }
}

const PatchManagerLinuxState = {
    filters: {},
    change: false,
    filtersType: {
        operating_system: {
            label: 'Operating System',
            default: true,
            show: true,
            optionsName: 'operating_system'
        },
        asset_status: {
            label: 'Asset Status',
            default: true,
            show: true,
            options: [
                { value: 'Online', label: 'Online' },
                { value: 'Offline', label: 'Offline' }
            ]
        },
        groups: {
            label: 'Groups',
            default: false,
            show: false,
            optionsName: 'groups'
        }
    }
}

const PatchHistoryWindowsState = {
    filters: {},
    change: false,
    filtersType: {
        windows_type: {
            label: 'Windows Type',
            default: true,
            show: true,
            options: [
                { value: [0, 1], label: 'Workstations' },
                { value: [2, 3, 4, 5], label: 'Servers' }
            ]
        },
        installation_status: {
            label: 'Installation Status',
            default: true,
            show: true,
            options: [
                { value: 'N/A', label: 'N/A' },
                { value: 'Succeeded', label: 'Succeeded' },
                { value: 'Pending', label: 'Pending' },
                { value: 'In Progress', label: 'In Progress' },
                { value: 'Failed', label: 'Failed' },
                { value: 'DOWNLOAD FAILED', label: 'DOWNLOAD FAILED' },
                { value: 'ALREADY INSTALLED', label: 'ALREADY INSTALLED' }
            ]
        },
        asset_status: {
            label: 'Asset Status',
            default: true,
            show: true,
            options: [
                { value: 'Online', label: 'Online' },
                { value: 'Offline', label: 'Offline' }
            ]
        },
        groups: {
            label: 'Groups',
            default: false,
            show: false,
            optionsName: 'groups'
        },
        serial_number: {
            label: 'Serial Number',
            default: false,
            show: false,
            optionsName: 'serial_number'
        },
        operating_system: {
            label: 'Operating System',
            default: true,
            show: true,
            optionsName: 'operating_system'
        },
        update_category: {
            label: 'Update Category',
            default: false,
            show: false,
            optionsName: 'update_category'
        }
    }
}

const PatchHistoryLinuxState = {
    filters: {},
    change: false,
    filtersType: {
        operating_system: {
            label: 'Operating System',
            default: true,
            show: true,
            optionsName: 'operating_system'
        },
        asset_status: {
            label: 'Asset Status',
            default: true,
            show: true,
            options: [
                { value: 'Online', label: 'Online' },
                { value: 'Offline', label: 'Offline' }
            ]
        },
        installation_status: {
            label: 'Installation Status',
            default: true,
            show: true,
            options: [
                { value: 'N/A', label: 'N/A' },
                { value: 'Succeeded', label: 'Succeeded' },
                { value: 'Pending', label: 'Pending' },
                { value: 'In Progress', label: 'In Progress' },
                { value: 'Failed', label: 'Failed' },
                { value: 'DOWNLOAD FAILED', label: 'DOWNLOAD FAILED' },
                { value: 'ALREADY INSTALLED', label: 'ALREADY INSTALLED' }
            ]
        },
        groups: {
            label: 'Groups',
            default: false,
            show: false,
            optionsName: 'groups'
        }
    }
}

const PatchIntelligenceState = {
    filters: {},
    change: false,
    filtersType: {
        exploited: {
            label: 'Exploited',
            default: true,
            show: true,
            options: [
                { value: 'Actively Exploited', label: 'Actively Exploited' },
                { value: 'Unproven', label: 'Unproven' }
            ]
        },
        status: {
            label: 'Status',
            default: true,
            show: true,
            options: [
                { value: 'Solved', label: 'Solved' },
                { value: 'Not Solved', label: 'Not Solved' }
            ]
        },
        kbs: {
            label: 'KBS',
            default: false,
            show: false,
            optionsName: 'kbs'
        },
        cve: {
            label: 'CVE',
            default: false,
            show: false,
            optionsName: 'cve'
        },
        restart_required: {
            label: 'Restart Required',
            default: false,
            show: false,
            optionsName: 'restart_required'
        },
        sub_type: {
            label: 'Sub Type',
            default: false,
            show: false,
            optionsName: 'sub_type'
        }
    }
}

const ApplicationsSecurityAssetsState = {
    filters: {},
    change: false,
    filtersType: {
        asset_type: {
            label: 'Asset Type',
            default: true,
            show: true,
            options: [
                { category: 1, value: 'windows', label: 'Windows' },
                { category: 1, value: 'Web Server', label: 'Web Server' },
                { category: 1, value: 'linux', label: 'Linux' },
                { category: 1, value: 'network', label: 'Network' },
                { category: 1, value: 'database', label: 'Database' },
                { category: 2, value: 'Printers', label: 'Printers' },
                { category: 2, value: 'Productivity Software', label: 'Productivity Software' }
            ]
        },
        windows_type: {
            label: 'Windows Type',
            default: true,
            show: true,
            options: [
                { value: [0, 1], label: 'Workstations' },
                { value: [2, 3, 4, 5, 10], label: 'Servers' }
            ]
        },
        labels: {
            label: 'Labels',
            default: true,
            show: true,
            optionsName: 'labels'
        },
        malware_protection: {
            label: 'Malware Protection',
            default: true,
            show: true,
            optionsName: 'malware_protection'
        },
        agent_version: {
            label: 'Agent Version',
            default: true,
            show: true,
            optionsName: 'agent_version'
        },
        asset_status: {
            label: 'Asset Status',
            default: true,
            show: true,
            options: [
                { value: 'Online', label: 'Online' },
                { value: 'Offline', label: 'Offline' }
            ]
        },
        scan_status: {
            label: 'Scan Status',
            default: true,
            show: true,
            options: [
                { value: 'Running', label: 'Running' },
                { value: 'Stopped', label: 'Stopped' }
            ]
        },
        security_rating: {
            label: 'Security Rating',
            default: true,
            show: true,
            options: [
                { value: 1, label: '0-25' },
                { value: 2, label: '25-50' },
                { value: 3, label: '50-75' },
                { value: 4, label: '75-100' }
            ]
        },
        instance_id: {
            label: 'Instance Id',
            default: false,
            show: false,
            options: [
                { value: 'Cloud', label: 'Cloud' },
                { value: 'Not Cloud', label: 'Not Cloud' }
            ]
        }
    }
}
const assetsOrchestratorState = {
    filters: {},
    change: false,
    filtersType: {
        asset_type: {
            label: 'Asset Type',
            hide: false,
            default: true,
            show: true,
            options: [
                { category: 1, value: 'windows', label: 'Windows' },
                { category: 1, value: 'Web Server', label: 'Web Server' },
                { category: 1, value: 'linux', label: 'Linux' },
                { category: 1, value: 'network', label: 'Network' },
                { category: 1, value: 'database', label: 'Database' },
                { category: 2, value: 'Printers', label: 'Printers' },
                { category: 2, value: 'Productivity Software', label: 'Productivity Software' }
            ]
        },
        windows_type: {
            label: 'Windows Type',
            hide: true,
            default: false,
            show: false,
            options: [
                { value: [0, 1], label: 'Workstations' },
                { value: [2, 3, 4, 5, 10], label: 'Servers' }
            ]
        },
        domain_rule: {
            label: 'Domain Rule',
            hide: true,
            default: true,
            show: true,
            options: [
                { value: 0, label: 'Standalone Workstation' },
                { value: 1, label: 'Member Workstation' },
                { value: 2, label: 'Standalone Server' },
                { value: 3, label: 'Member Server' },
                { value: 4, label: 'Backup Domain Controller' },
                { value: 5, label: 'Primary Domain Controller' }
            ]
        },
        labels: {
            label: 'Labels',
            hide: false,
            default: true,
            show: true,
            optionsName: 'labels'
        },
        malware_protection: {
            label: 'Malware Protection',
            hide: false,
            default: true,
            show: true,
            optionsName: 'malware_protection'
        },
        agent_version: {
            label: 'Agent Version',
            hide: false,
            default: true,
            show: true,
            optionsName: 'agent_version'
        },
        asset_status: {
            label: 'Asset Status',
            hide: false,
            default: true,
            show: true,
            options: [
                { value: 'Online', label: 'Online' },
                { value: 'Offline', label: 'Offline' }
            ]
        },
        scan_status: {
            label: 'Scan Status',
            hide: false,
            default: true,
            show: true,
            options: [
                { value: 'Running', label: 'Running' },
                { value: 'Stopped', label: 'Stopped' }
            ]
        },
        security_rating: {
            label: 'Security Rating',
            hide: false,
            default: true,
            show: true,
            options: [
                { value: 1, label: '0-25' },
                { value: 2, label: '25-50' },
                { value: 3, label: '50-75' },
                { value: 4, label: '75-100' }
            ]
        },
        instance_id: {
            label: 'Instance Id',
            hide: false,
            default: false,
            show: false,
            options: [
                { value: 'Cloud', label: 'Cloud' },
                { value: 'Not Cloud', label: 'Not Cloud' }
            ]
        },
        architectures: {
            label: 'Architectures',
            hide: false,
            default: false,
            show: false,
            optionsName: 'architectures'
        },
        ou: {
            label: 'OU',
            hide: false,
            default: false,
            show: false,
            optionsName: 'ou'
        },
        dc: {
            label: 'DC',
            hide: false,
            default: false,
            show: false,
            optionsName: 'dc'
        }
    }
}

const RMMScriptHubState = {
    filters: {},
    change: false,
    filtersType: {
        script_category: {
            label: 'Script Category',
            default: true,
            show: true,
            optionsName: 'script_category'
        },
        script_languages: {
            label: 'Script Languages',
            default: true,
            show: true,
            optionsName: 'script_languages'
        },
        script_run_as: {
            label: 'Script Run As',
            default: true,
            show: true,
            optionsName: 'script_run_as'
        },
        script_created_modified_by: {
            label: 'Script Created/Modified By',
            default: true,
            show: true,
            optionsName: 'script_created_modified_by'
        }

    }
}

const RMMSchedulerScriptState = {
    filters: {},
    change: false,
    filtersType: {
        script_languages: {
            label: 'Script Languages',
            default: true,
            show: true,
            optionsName: 'script_languages'
        },
        frequency: {
            label: 'Frequency',
            default: true,
            show: true,
            options: [
                {
                    label: 'Repeated',
                    value: 'Repeated'
                },
                {
                    label: 'Once',
                    value: 'Once'
                }
            ]
        },
        created_by: {
            label: 'Created by',
            default: true,
            show: true,
            optionsName: 'created_by'
        }

    }
}

const RMMAuditingState = {
    filters: {},
    change: false,
    filtersType: {
        script_languages: {
            label: 'Script Languages',
            default: true,
            show: true,
            optionsName: 'script_languages'
        },
        created_by: {
            label: 'Created by',
            default: true,
            show: true,
            optionsName: 'created_by'
        }

    }
}

export const initialState = {
    patchManagerWindows: patchManagerWindowsState,
    patchManagerLinux: PatchManagerLinuxState,
    patchHistoryWindows: PatchHistoryWindowsState,
    patchHistoryLinux: PatchHistoryLinuxState,
    patchIntelligence: PatchIntelligenceState,
    applicationsSecurityAssets: ApplicationsSecurityAssetsState,
    assetsOrchestrator: assetsOrchestratorState,
    RMMScriptHub: RMMScriptHubState,
    RMMSchedulerScript: RMMSchedulerScriptState,
    RMMAuditing: RMMAuditingState
}