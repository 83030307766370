// ** Initial State
const initialState = {
    labels: [],
    assetInfo: {},
    monitoring: {},
    collapseCard: true,
    changeTab: false,
    controlsActions: {
        applicability: false,
        execlude: false,
        remediation: false,
        notes: false,
        attachments: false,
        ticket: false
    }
}

const assetPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ASSET_DATA':
            return { ...state, ...action.data }

        case 'ASSET_INFO':
            return {
                ...state,
                assetInfo: {
                    ...state.assetInfo,
                    ...action.data
                }
            }

        case 'COLLAPSE_CARD': {
            return { ...state, collapseCard: action.val }
        }

        case 'CHANGE_TAP': {
            return { ...state, collapseCard: false, changeTab: action.val }
        }

        case 'CONTROLS_ACTIONS':
            return { ...state, controlsActions: action.val }

        case 'SELECTED_LABELS':
            return { ...state, assetInfo: { ...state.assetInfo, selected_labels: action.data } }

        case 'LABELS':
            return { ...state, labels: action.data }

        default:
            return state
    }
}

export default assetPageReducer