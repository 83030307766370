const initialState = {
    events: [],
    pageLoading: true,
    loading: false,
    activeEvent: null
}


const PciCalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PCI_CALENDAR_EVENTS': {
            const start_date_calendar = action.start_date_calendar
            const end_date_calendar = action.end_date_calendar
            return {
                ...state,
                events: action.value,
                pageLoading: false,
                start_date_calendar,
                end_date_calendar
            }
        }
        case 'PCI_CALENDAR_ACTIVE_EVENT': {
            return {
                ...state,
                activeEvent: action.value
            }
        }
        case 'PCI_CALENDAR_PAGE_LOADING': {
            return {
                ...state,
                loading: action.value
            }
        }
        default:
            return state
    }
}
export default PciCalendarReducer