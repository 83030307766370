const initialState = {
    currentStep: 1,
    configurationsWizardCurrentStep: 0,
    agentType: 'Windows',
    windowsData: {},
    linuxData: {},
    linuxSubType: 1,
    finish: false
}


const CymetricxAgentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AGENT_DATA': {
            return {
                ...state,
                windowsData: action.value?.windowsData,
                linuxData: action.value?.linuxData
            }
        }
        case 'AGENT_TYPE': {
            return {
                ...state,
                agentType: action.value // Merging action.value into the state
            }
        }
        case 'LINUX_SUB_TYPE': {
            return {
                ...state,
                linuxSubType: action.value // Merging action.value into the state
            }
        }
        case 'PREVIOUS_STEP_AGENT_WIZARD': {
            let tempStep = state.currentStep
            if (tempStep > 1) tempStep -= 1
            return { ...state, currentStep: tempStep }
        }

        case 'NEXT_STEP_AGENT_WIZARD': {
            let tempStep = state.currentStep
            if (tempStep < 2) tempStep += 1
            return { ...state, currentStep: tempStep }
        }

        case 'STEP_CONFIGURATIONS_AGENT_WIZARD': {
            return { ...state, configurationsWizardCurrentStep: action.value }
        }
        case 'PREVIOUS_STEP_CONFIGURATIONS_AGENT_WIZARD': {
            let tempStep = state.configurationsWizardCurrentStep
            if (tempStep > 1) tempStep -= 1
            return { ...state, configurationsWizardCurrentStep: tempStep }
        }

        case 'NEXT_STEP_CONFIGURATIONS_AGENT_WIZARD': {
            let tempStep = state.configurationsWizardCurrentStep
            if (tempStep < 5) tempStep += 1
            return { ...state, configurationsWizardCurrentStep: tempStep }
        }

        // case 'FINISH_POLICY_WIZARD': {
        //     return { ...state, finish: action.value }
        // }
        case 'INITIAL_AGENT_WIZARD': {
            return initialState
        }
        default:
            return state
    }
}
export default CymetricxAgentReducer